<script setup lang="ts">
const props = defineProps({
    blok: {
        type: Object,
        default: () => ({}),
    },
});

const root = ref<HTMLElement | null>(null);

onMounted(() => {
    if (props.blok.showOnScroll) {
        let observer: IntersectionObserver;

        const callback = function (entries: any) {
            entries.forEach((entry: any) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show-on-scroll');
                    observer.unobserve(entry.target);
                }
            });
        };

        observer = new IntersectionObserver(callback);

        if (root.value != null) {
            root.value.classList.add('opacity-0');
            observer.observe(root.value);
        }
    }
});
</script>

<template>
    <section
        ref="root"
        :class="`relative bg-no-repeat bg-cover bg-center bg-gray-300 bg-blend-multiply`"
        :style="`background-image: url('${blok.backgroundImage.filename}/m/1000x0')`"
    >
        <video
            class="absolute inset-0 object-cover w-full h-full"
            :src="blok.backgroundVideo.filename"
            autoplay
            loop
            muted
            playsinline
        ></video>
        <div class="absolute inset-0" style="mix-blend-mode: multiply"></div>
        <div
            class="bg-gradient-to-t from-[rgba(0,0,0,0.5)] bg-blend-multiply h-[500px] relative flex items-center justify-center"
        >
            <div class="absolute bottom-0 mx-auto pb-8 px-8 text-center">
                <h1 v-if="blok.h1" class="mb-4 leading-none text-white font-bold">
                    {{ blok.title }}
                </h1>
                <h2 v-if="!!!blok.h1" class="mb-4 leading-none text-white font-bold">
                    {{ blok.title }}
                </h2>
                <p class="mb-8 text-lg font-normal text-white lg:text-xl">
                    {{ blok.text }}
                </p>
                <div
                    class="flex-wrap mb-8 lg:mb-16 space-x-4 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4"
                >
                    <StoryblokComponent v-for="item in blok.ctas" :key="item._uid" :blok="item" />
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
@keyframes anim {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.show-on-scroll {
    animation: anim 1s ease-in forwards;
}
</style>
